export default {
    lon: "LON",
    lat: "LAT",
    rot: "ROT",
    sog: "SOG",
    cog: "COG",
    hdg: "HDG",
    time: "DATE(UTC)",
    source: "type"
}
