import Vue from "vue";
import display from "@/configure/display/service/aisTrack";
import {AisTrackEntity, AisTrackQuery} from "@/core/entity/service/aisTrack";
import {DataOptions, DataTableHeader} from "vuetify";
import splitPane from '../../standard/split-pane/index';
import service from "@/core/service";
import tip from "@/configure/tip";
import TrackMap from "./track-map.vue"

export default Vue.extend({
    components: {
        splitPane,
        TrackMap
    },
    data: () => ({
        show: true,
        loading: false,
        headers: <DataTableHeader[]>[
        ],
        options: <DataOptions><unknown>{sortBy: [], sortDesc: [], page: 1, itemsPerPage: 10},
        data: <AisTrackQuery>{},
        result: <AisTrackEntity[]>[],
        resultLength: 0,
        datetime: "",
        display
    }),
    methods: {
        async query() {

        },
        async del(key: number) {

        },
        async save() {
            //this.result全部保存
        },
    }
});
